import Head from 'next/head';

const Versioning = () => {
  return (
    <Head>
      <meta name="version" content="1.1.3.17116" />
      <meta name="build-date" content="2024-12-13T17:37:03Z" />
    </Head>
  );
};

export default Versioning;
